import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { GlobalStyles } from '@/styles/globalStyles';
import { Header } from '@/features/new-landing-page/components/header';
import { Footer } from '@/features/new-landing-page/components/footer';
import { PageWrapper } from '@/features/new-landing-page/pages/homepage';
import { CaseStudiesSection } from '@/features/new-landing-page/sections/case-studies';
import { DisruptSection } from '@/features/new-landing-page/sections/disrupt';
import { DemoVideoSection } from '@/features/new-landing-page/sections/demo-video';
import { ReviewsSection } from '@/features/new-landing-page/sections/reviews';

export function VideoDemo() {
  return (
    <PageWrapper>
      <GlobalStyles />
      <DemoGlobalStyles />
      <Header />
      <DemoVideoSection />
      <CaseStudiesSection noCta />
      <ReviewsSection />
      <DisruptSection />
      <Footer />
    </PageWrapper>
  );
}

export const DemoGlobalStyles = createGlobalStyle`
  #header {
    background: rgb(26, 27, 49) !important;

    .gatsby-image-wrapper {
      filter: invert(1);
      color: white;
    }

    .strapi-textual {
      filter: invert(1);
    }

    button {
      filter: invert(1);
    }

    .trial {
      display: none;
    }
  }

  section {
    background: #f7f9fb !important;
  }
`;
