import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Section } from '@/features/new-landing-page/components/section';
import { StrapiTextual } from '@/features/strapi/components/textual';
import { VideoDemoContent } from '@/features/new-landing-page/pages/video-demo-content';
// @ts-ignore
import backgroundImage from '../assets/video-demo/bg.png';
import { VSpace } from '@/components/spacing';
import { routes } from '@/webapi/routes';
import { DemoVideoLegend } from '@/features/new-landing-page/components/demo-video-legend';
import { awaitCondition } from '@/utils/sync';
import { breakpoints } from '@/components/responsive';

export function DemoVideoSection() {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const [videoElem, setVideoElem] = useState<HTMLVideoElement | undefined>();

  useEffect(() => {
    if (ref?.current) {
      awaitCondition(
        () => !!ref?.current?.contentDocument?.querySelector(`video`),
        500,
        20,
      ).then(() => {
        const video = ref?.current?.contentDocument?.querySelector(`video`);
        if (video) {
          setVideoElem(video);
        }
      });
    }
  }, [ref]);

  useEffect(() => {
    if (videoElem) {
      videoElem.volume = 0.5;
    }
  }, [videoElem]);

  return (
    <CustomSection>
      <HeartLogo />
      <StrapiTextual {...VideoDemoContent.title} />
      <StrapiTextual {...VideoDemoContent.subTitle} />
      <VSpace value={1} />
      <VideoWrapper>
        <BackgroundOverlay className="only-desktop" />
        <iframe
          ref={ref}
          id="demo-video-iframe"
          title="Visually Product Demo"
          src={routes.cleanQuickPreview(
            `https://iframe.mediadelivery.net/embed/169819/a05f5e18-0be2-4225-96bb-789b36dd47b2?autoplay=false&loop=false&muted=false&preload=true`,
          )}
          loading="lazy"
          allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
        />
      </VideoWrapper>
      <VSpace value={1} />
      <DemoVideoLegend videoElem={videoElem} />
      <VSpace value={1} mobileValue={3} />
      <StrapiTextual {...VideoDemoContent.cta} />
      <VSpace value={1} mobileValue={3} />
    </CustomSection>
  );
}

const HeartLogo = () => (
  <>
    <StaticImage
      className="heart only-desktop"
      src="../assets/video-demo/heart.png"
      alt="visually heart logo"
      placeholder="none"
      loading="eager"
      height={55}
    />
    <StaticImage
      className="heart only-mobile"
      src="../assets/video-demo/heart.png"
      alt="visually heart logo"
      placeholder="none"
      loading="eager"
      height={40}
    />
  </>
);

const CustomSection = styled(Section)`
  && {
    position: relative;
    background: rgb(26, 27, 49) !important;
  }

  .strapi-textual {
    color: white;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.15);
    }
    100% {
      transform: scale(1);
    }
  }

  .heart {
    animation: pulse 2.5s ease-in-out;
    animation-iteration-count: 10;
  }
`;

const VideoWrapper = styled.div`
  padding-bottom: 42%;
  width: 75vw;
  position: relative;
  border-radius: 2rem;
  z-index: 0;

  ${breakpoints.down(`md`)} {
    width: 90vw;
    padding-bottom: 50%;
    border-radius: 1.2rem;
  }

  iframe {
    background: url('https://vz-c7848e4e-e4e.b-cdn.net/a05f5e18-0be2-4225-96bb-789b36dd47b2/thumbnail_0931da1f.jpg');
    background-size: cover;
    border-radius: 2rem;
    overflow: hidden;
    appearance: none;
    border: none;
    height: 100%;
    width: 100%;
    position: absolute;
    inset: 0 0 0 0;
    box-shadow: 0 2px 15px 2px rgba(18, 31, 221, 0.82);

    ${breakpoints.down(`md`)} {
      border-radius: 1.2rem;
    }
  }
`;

const BackgroundOverlay = styled.div`
  z-index: -1;
  position: absolute;
  top: -5rem;
  height: 108%;
  width: 100vw;
  margin-left: calc(50% - 50vw);

  background: url(${backgroundImage});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;
