import { StrapiTextualProps } from '@/features/strapi/components/textual';

export const VideoDemoContent = {
  title: {
    size: `4.4rem`,
    weight: `bold`,
    text: `Meet Visually`,
    mobileMultiplier: 0.8,
  } as StrapiTextualProps,
  subTitle: {
    size: `2.2rem`,
    weight: `normal`,
    lineHeight: `1.8`,
    text: `Ready to take control over your site and double conversions?\n Watch Visually's Optimization & Personalization demo to explore the full power of the platform in action`,
    mobileText: `Ready to take control over your site and double\n conversions? Watch Visually's Optimization & \n Personalization demo to explore the full power of \n the platform in action`,
  } as StrapiTextualProps,
  cta: {
    size: `2.2rem!important`,
    weight: `normal`,
    text: `Get Visually for free`,
    className: `btn primary-btn small`,
    href: `https://apps.shopify.com/visually-io?utm_source=website&utm_medium=product-demo`,
  } as StrapiTextualProps,
};
